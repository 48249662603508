<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount } from "svelte";
import ConfettiGenerator from "confetti-js";
import { Row, Grid, Column } from "carbon-components-svelte";
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    var confettiSettings = { target: 'confetti' };
    var confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
}));
</script>

<style>
  div {
    margin: auto;
    margin-top: 60px;
  }
</style>

<div id="bg">

  <Grid>
    <Row>
      <Column>
        <p>That's it! The next time a meeting is Cloud Recorded on Zoom, it'll automatically be uploaded to a new folder. Yes!</p>
        <br/>
        <p>We hope you find it helpful!<br/>Eloy, Maxim & Orta</p>
        <br/><br/>
      </Column>
    </Row>
  </Grid>
</div>
