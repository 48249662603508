<script lang="ts">import { Row, Grid, Column } from "carbon-components-svelte";
const apiURL = process.env.KISS_SERVICES_FUNCS_URL;
let gdriveAuthURL = apiURL + "/api/Zoom-GoogleAuth";
let dropboxAuthURL = apiURL + "/api/Zoom-DropboxAuth";
</script>

<style lang="css">
  a {
    text-align: center;
    margin: auto;
    margin-top: 100px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  a:hover {
    margin-top: 101px;
  }
  a:active {
    margin-top: 102px;
  }
  img {
    margin-top: 8px;
    width: 200px;
    height: 44px;
  }
  div {
    margin: 60px auto ;
    min-width: 280px;
  }
  p {
    text-align: center;
  }
</style>

<div>

<Grid>
  <Row>
    <Column>
      <p>Connect to either your Google Drive, or Dropbox.</p>
    </Column>
    </Row>
    <Row>
      <Column>
        <a href={gdriveAuthURL}><img src="/images/btn_google_signin_light_normal_web@2x.png" alt="Google Drive logo for clicking to start logging in" /></a>
        <a href={dropboxAuthURL}><img src="/images/btn_dropbox_signin_light_normal_web.png" alt="Dropxox logo for clicking to start logging in" /></a>
    </Column>
  </Row>
</Grid>
</div>
