<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { onMount } from "svelte";
const apiURL = process.env.KISS_SERVICES_FUNCS_URL;
let migrationMeta = {
    fileToMigrate: undefined,
    folderToGoTo: undefined,
    newName: undefined,
};
let filterString = "";
$: filteredFiles = ((allFolders === null || allFolders === void 0 ? void 0 : allFolders.files) || []).filter(file => {
    if (filterString === "") {
        return true;
    }
    const thisFile = file.name.toLowerCase().includes(filterString.toLowerCase());
    if (thisFile)
        return true;
    const parents = (file.parents || []).map(p => allFolderNameMap.get(p)).filter(Boolean);
    return parents.find(p => p.toLowerCase().includes(filterString.toLowerCase()));
});
let newButtonString = null;
let fileMetaInfo = undefined;
let allFolders = undefined;
let allFolderNameMap = undefined;
$: fullFilename = !migrationMeta.newName
    ? "[No name yet]"
    : migrationMeta.newName + "." + ((_a = migrationMeta.fileToMigrate) === null || _a === void 0 ? void 0 : _a.name.split(".").pop());
let searching = false;
$: if (filterString)
    searchForString(filterString);
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    try {
        fetch(`${apiURL}/api/Gdrive-FileRenamer?task=myFiles`, { credentials: "include" }).then((res) => __awaiter(void 0, void 0, void 0, function* () {
            fileMetaInfo = yield res.json();
        }));
    }
    catch (err) {
        console.error(err);
        fileMetaInfo = null;
    }
    try {
        fetch(`${apiURL}/api/Gdrive-FileRenamer?task=folders`, { credentials: "include" }).then((res) => __awaiter(void 0, void 0, void 0, function* () {
            allFolders = yield res.json();
            allFolderNameMap = new Map(allFolders.files.map(file => [file.id, file.name]));
        }));
    }
    catch (err) {
        console.error(err);
        allFolders = null;
    }
}));
const addButtonToUser = (name) => {
    fetch(`${apiURL}/api/Gdrive-FileRenamer?task=addButton&button=${encodeURIComponent(name)}`, {
        credentials: "include",
    }).then((res) => __awaiter(void 0, void 0, void 0, function* () {
        allFolders = yield res.json();
    }));
};
const deleteFileByID = (id) => {
    fetch(`${apiURL}/api/Gdrive-FileRenamer?task=rm&id=${encodeURIComponent(id)}`, {
        credentials: "include",
    }).then((res) => __awaiter(void 0, void 0, void 0, function* () {
        fileMetaInfo = Object.assign(Object.assign({}, fileMetaInfo), { files: [...fileMetaInfo.files].filter(file => file.id === id) });
    }));
};
const migrateFile = () => {
    var _a, _b;
    fetch(`${apiURL}/api/Gdrive-FileRenamer?task=migrate&fileId=${encodeURIComponent((_a = migrationMeta.fileToMigrate) === null || _a === void 0 ? void 0 : _a.id)}&folderId=${encodeURIComponent((_b = migrationMeta.folderToGoTo) === null || _b === void 0 ? void 0 : _b.id)}&newName=${encodeURIComponent(migrationMeta.newName)}`, {
        credentials: "include",
    }).then((res) => __awaiter(void 0, void 0, void 0, function* () {
        fileMetaInfo = yield res.json();
        migrationMeta = {
            fileToMigrate: undefined,
            folderToGoTo: undefined,
            newName: undefined,
        };
    }));
};
const appendToNewName = (str) => {
    migrationMeta.newName = migrationMeta.newName ? migrationMeta.newName + " - " + str : str;
};
const addDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dateString = `${month}.${day}.${year}`;
    appendToNewName(dateString);
};
let timer = null;
const searchForString = v => {
    clearTimeout(timer);
    timer = setTimeout(() => {
        searching = true;
        fetch(`${apiURL}/api/Gdrive-FileRenamer?task=search&search=${encodeURIComponent(filterString)}`, {
            credentials: "include",
        }).then((res) => __awaiter(void 0, void 0, void 0, function* () {
            searching = false;
            const data = yield res.json();
            const searchResults = data.files;
            const newFiles = searchResults.filter(r => !allFolders.files.find(f => f.id === r.id));
            allFolders = { files: [...allFolders.files, ...newFiles] };
            allFolderNameMap = new Map(allFolders.files.map(file => [file.id, file.name]));
        }));
    }, 750);
};
function msToTime(milli) {
    let time = new Date(parseInt(milli));
    let hours = time.getUTCHours();
    let minutes = time.getUTCMinutes();
    let seconds = time.getUTCSeconds();
    if (hours)
        return hours + "h " + minutes + "m " + seconds + "s";
    if (minutes)
        return minutes + "m " + seconds + "s";
    return seconds + "s";
}
function formatBytes(bytes, decimals = 2) {
    if (!+bytes)
        return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
</script>

<h2>Gdrive Migration tool</h2>

<p>This page lets you quickly migrate the files which capture has saved into different folders in your Google Drive.</p>

<h3>Files to migrate</h3>
{#if fileMetaInfo}
  <div style="display:flex; flex-direction:row; flex-wrap: wrap;">
    {#if fileMetaInfo.files?.length}
      {#each fileMetaInfo.files as file}
        <div class="box" class:selected={file.id === migrationMeta?.fileToMigrate?.id} style={"width: 320px;"}>
          <a href={`https://drive.google.com/file/d/${file.id}/view`} target="_blank">
            <img src={file.thumbnailLink} style="width: 100%;" alt="thumbnail" />
            <p>{file.name}</p>
          </a>

          <div class="inline-buttons">
            <button
              on:click={() => {
                migrationMeta.fileToMigrate = file
              }}>{file.id === migrationMeta?.fileToMigrate?.id ? "Selected" : "Select"}</button
            >

            {#if file.videoMediaMetadata}
              <p>{msToTime(file.videoMediaMetadata.durationMillis)} {file.size ? formatBytes(file.size) : ""}</p>
              <button
                on:click={() => {
                  deleteFileByID(file.id)
                }}>Delete</button
              >
            {/if}
          </div>
        </div>
      {/each}
    {:else}
      <p>No files to migrate! Congrats</p>
    {/if}
  </div>
{:else}
  <p>Downloading files in your migrate folder</p>
{/if}

<!-- <pre><code style="width:300px; display: block;">{JSON.stringify(fileMetaInfo, null, "  ")}</code></pre> -->

<h3>Migrate them to</h3>

{#if allFolders}
  <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
    <input type="text" bind:value={filterString} placeholder="Filter" style="width: 300px; display: block;" />
    <p style="margin-left:20px">
      {filteredFiles.length === allFolders.files.length ? "" : `${filteredFiles.length} of `}{allFolders.files.length} folders
    </p>
  </div>
  {#each filteredFiles as file}
    <div
      class="row"
      class:selected={file.id === migrationMeta?.folderToGoTo?.id}
      on:click={() => {
        migrationMeta.folderToGoTo = file
      }}
    >
      <p>
        <span style="margin-right: 20px;"
          ><button>{file.id === migrationMeta?.folderToGoTo?.id ? "Selected" : "Select"}</button></span
        >{file.name}
        <br />
        {#if file.parents}
          {#each file.parents as parent}
            &gt; <span style="margin-left: 10px;">{allFolderNameMap.get(parent) || "Root"}</span>
          {/each}
        {/if}
      </p>
    </div>
  {/each}
{:else}
  <p>Downloading a list of all your folders</p>
{/if}

{#if searching}{/if}

<h3>With a new name</h3>

<div style="display: flex; flex-direction: row;">
  <input type="text" bind:value={migrationMeta.newName} placeholder="New name" style="width: 300px; display: block;" />
  {#if migrationMeta.fileToMigrate}
    <p style="margin-left: 20px;">{"." + migrationMeta.fileToMigrate.name.split(".").pop()}</p>
  {/if}
</div>

<div style="display:flex;flex-direction:row;flex-wrap:wrap;">
  <button on:click={addDate}>Add date</button>

  {#each fileMetaInfo?.buttons || [] as button}
    <button style="margin-top:20px" on:click={() => appendToNewName(button)}>{button}</button>
  {/each}

  {#if newButtonString === null}
    <button on:click={() => (newButtonString = "")} class="bx--btn bx--btn--tertiary">Add new button</button>
  {/if}
</div>

{#if newButtonString !== null}
  <h3>Add a new button</h3>
  <div style="display:flex;flex-direction:row">
    <input
      type="text"
      bind:value={newButtonString}
      placeholder="New button name"
      style="width: 300px; display: block;margin-top: 10px;"
    />
    <button
      on:click={() => {
        fileMetaInfo = { ...fileMetaInfo, buttons: [...(fileMetaInfo?.buttons || []), newButtonString] }
        addButtonToUser(newButtonString)
      }}>Add the button</button
    >
  </div>
{/if}

<div style="height: 100px" />

<div style="position:fixed; bottom:0; left:0;right:0; background-color: #00000010; padding: 1em;">
  <div style="display:flex; flex-direction: row; justify-content: space-between;">
    <p>
      Moving {migrationMeta.fileToMigrate?.name || "[Not set]"}<br />to {migrationMeta?.folderToGoTo?.name ||
        "[Not set]"} as <strong>{fullFilename}</strong>
    </p>
    <button
      style="float:right;"
      class="bx--btn bx--btn--primary"
      disabled={!migrationMeta?.newName}
      on:click={migrateFile}>Migrate</button
    >
  </div>
</div>

<!-- <pre><code style="width:300px">{JSON.stringify(allFolders)}</code></pre> -->
<style>
  h2 {
    margin-bottom: 24px;
  }
  h3 {
    margin-top: 26px;
    margin-bottom: 18px;
  }
  button {
    margin-right: 1em;
  }
  .box {
    border: 1px solid #aaa;
    border-radius: 2px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 1em;
    margin: 0 1em 1em 0;
  }

  .box.selected,
  .row.selected {
    border: 2px solid var(--cds-interactive-04, #0f62fe);
    box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.2);
  }

  .row {
    border: 1px solid #aaa;
    border-radius: 2px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 1em;
    margin: 1em 1em 1em 0;
  }

  .inline-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>
