<script lang="ts">const apiURL = process.env.KISS_SERVICES_FUNCS_URL;
const isDev = document.location.host.includes("localhost");
let zoomAuthURL = apiURL + "/api/Zoom-ZoomAuth" + (isDev ? "?dev=true" : "");
export let showAsLogin;
let zoomImgSrc = showAsLogin
    ? "/images/zoom-logo.png"
    : "https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png";
</script>

<style lang="css">
  a {
    width: 200px;
    height: 44px;
    text-align: center;
    background-color: white;
    border-bottom: 2px solid lightgrey;
    margin: auto;
    margin-top: 100px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  a:hover {
    background-color: #fbfbfb;
    border-bottom: 1px solid lightgrey;
    margin-top: 101px;
  }
  a:active {
    margin-top: 102px;
  }
  img {
    width: 118px;
    height: 28px;
    margin-top: 8px;
  }
</style>

<a href={zoomAuthURL}><img src={zoomImgSrc} height="32" alt="Zoom logo for clicking to start logging in" /></a>
