<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
;
import { Tab, Tabs, TabContent } from "carbon-components-svelte";
import { onMount } from "svelte";
import Backups from "./Backups.svelte";
import Webhooks from "./Webhooks.svelte";
import Accounts from "./Accounts.svelte";
const apiURL = process.env.KISS_SERVICES_FUNCS_URL;
let counts;
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const res = yield fetch(`${apiURL}/api/Zoom-ZoomDashboardAdminGetDetails?type=counts`, { credentials: "include" });
        counts = yield res.json();
        console.log({ counts });
    }
    catch (err) {
        console.log(err);
        document.location.replace("/");
    }
}));
</script>

<style>
  p {
    margin-bottom: 32px;
  }
</style>

<h3>Admin</h3>
<p>Dig deeper</p>

{#if counts}

<Tabs>
  <Tab label="Accounts" />
  <Tab label="Backup Logs" />
  <Tab label="Zoom Videos" />
  <div slot="content">
    <TabContent><Accounts totalAccounts={counts.accounts} /></TabContent>
    <TabContent><Backups totalLogs={counts.logs} /></TabContent>
    <TabContent><Webhooks totalVideos={counts.videos} /></TabContent> 
  </div>
</Tabs>

{/if}
