<script lang="ts">var _a, _b, _c, _d, _e;
;
import { StructuredList, StructuredListHead, StructuredListBody, StructuredListCell, StructuredListRow, } from "carbon-components-svelte";
import { timeSince } from "../utils/timeSince";
import { hasActiveTrial, hasActiveSubscription } from "@kiss/functions/lib/db/utils";
import Oauth from "../setup/Oauth.svelte";
export let info;
const headers = ["Date", "State", "Host", "Info"];
const rows = info.history.map(h => {
    if (h.type === "success") {
        return {
            date: timeSince(h.uploadState.uploadedAt) + " ago",
            state: "Uploaded",
            host: h.host,
            files: h.files,
        };
    }
    else {
        return {
            date: timeSince(h.uploadState.uploadedAt) + " ago",
            state: "Failed",
            host: h.host,
            reason: h.uploadState.errorState,
        };
    }
});
const isAdmin = ((_b = (_a = info.account) === null || _a === void 0 ? void 0 : _a.zoomAccount) === null || _b === void 0 ? void 0 : _b.accountID) === "bS8LJFWpS7W4o3hhu5wfwQ";
const gotoAdmin = () => document.location.replace("/admin");
const hasNoDeployments = ((_d = (_c = info.account) === null || _c === void 0 ? void 0 : _c.zoomAccount) === null || _d === void 0 ? void 0 : _d.deployments.length) === 0;
const isTrial = hasActiveTrial(info.account);
const trialEndDate = new Date(((_e = info.account) === null || _e === void 0 ? void 0 : _e.confirmedAt) || 0);
const showNumberOfUploads = info.history.length > 5;
const trialDisplay = new Intl.DateTimeFormat("en-US", { month: "short", day: "numeric" }).format(trialEndDate);
const isSubscribed = hasActiveSubscription(info.account);
</script>

{#if isTrial}
  <a href="/settings"><div style="float:right;">Trial ends on {trialDisplay}</div></a>
{:else if !isSubscribed}
  <h3>Your free trial finished on {trialDisplay}</h3>
  <p>
    {#if showNumberOfUploads}
      Capture uploaded {info.history.length} videos during your trial.
    {/if}
    <a href="/trial">Sign up to continue</a> or <a href="https://docs.google.com/document/d/1F_VpE6z_hq1TcopKfd-jpDKP-AD461USgMKNus8mmvw/edit">contact us</a> if you have
    questions.
  </p>
  <hr />
{/if}

{#if isAdmin}
  <button on:click={gotoAdmin}>Admin</button>
{/if}

{#if hasNoDeployments}
  <h3>You need to hook up a place to send your Zoom videos</h3>
  <Oauth {info} />
  <hr />
{/if}

<h3>Recent Transfers</h3>
<p>What files have we uploaded lately</p>

<StructuredList>
  <StructuredListHead>
    <StructuredListRow head>
      {#each headers as header}
        <StructuredListCell head>{header}</StructuredListCell>
      {/each}
    </StructuredListRow>
  </StructuredListHead>
  <StructuredListBody>
    {#if rows.length > 0}
      {#each rows as row}
        <StructuredListRow>
          <StructuredListCell>{row.date}</StructuredListCell>
          <StructuredListCell>{row.state}</StructuredListCell>
          <StructuredListCell>{row.host}</StructuredListCell>
          {#if row.files}
            <StructuredListCell>
              {#each row.files as file}
                <a href={file.href}>
                  {file.sanitizedFilename}
                </a>
              {/each}
            </StructuredListCell>
          {:else}
            <StructuredListCell>{row.reason}</StructuredListCell>
          {/if}
        </StructuredListRow>
      {/each}
    {:else}
      <StructuredListRow>
        <StructuredListCell>No uploads yet</StructuredListCell>
        <StructuredListCell />
        <StructuredListCell />
        <StructuredListCell />
      </StructuredListRow>
    {/if}
  </StructuredListBody>
</StructuredList>

<style>
  p {
    margin-bottom: 32px;
  }

  button {
    float: right;
    clear: right;
  }
  hr {
    margin: 2rem 0;
  }
  h3 {
    margin-bottom: 18px;
  }
</style>
