<script lang="ts">import Stripe from "../setup/Stripe.svelte";
;
export let info;
const showNumberOfUploads = info.history.length > 5;
const creationDate = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(info.account.createdAt);
</script>

<style>
  h2 {
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 18px;
  }
</style>

<h2>Sign up for Capture</h2>

<h4>You've finished a trial starting at {creationDate}</h4>

{#if showNumberOfUploads }
<p>Capture uploaded {info.history.length} videos during your trial.</p>
{/if} 

<Stripe user={info} />
