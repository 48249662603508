<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f;
;
;
;
import { Form, Dropdown, TextInput, Button, Row, Grid, Column, FormGroup, ContentSwitcher, Switch, Checkbox, } from "carbon-components-svelte";
import { utcHourForTime, getTimezoneName, closestTimeIndexForTimezone } from "./utils/dateFaffing";
export let user;
const apiURL = process.env.KISS_SERVICES_FUNCS_URL;
const hasContact = !!user.account.contact;
const hasSpectulativeContact = !!user.account.speculativeContact;
const userTimezone = getTimezoneName();
console.log(user);
const contact = hasContact
    ? user.account.contact
    : hasSpectulativeContact
        ? user.account.speculativeContact
        : {};
const uploadOptions = [
    { id: "gallery", text: "Gallery View (Recommended)" },
    // { id: 'speaker', text: 'Speaker View (Recommended)' },
    { id: "all videos", text: "All Video Files" },
    { id: "all media", text: "All Video and Audio Files" },
    { id: "all files", text: "All Files (media + transcripts)" },
];
let selectedOption = ("filesToBackup" in ((_a = user === null || user === void 0 ? void 0 : user.account) === null || _a === void 0 ? void 0 : _a.zoomAccount)
    ? uploadOptions.findIndex(o => o.id === user.account.zoomAccount.filesToBackup)
    : 0) || 0;
const transactionalOptions = [
    { id: "no updates", text: "Never" },
    { id: "after upload", text: "After Upload" },
    { id: "daily", text: "Daily" },
    { id: "weekly", text: "Weekly" },
];
const emailLabelToIDs = {
    "9:00": "early",
    "12:00": "noon",
    "17:00": "evening",
};
let deleteAfterUpload = !!((_c = (_b = user === null || user === void 0 ? void 0 : user.account) === null || _b === void 0 ? void 0 : _b.zoomAccount) === null || _c === void 0 ? void 0 : _c.deleteAfterUpload);
const emailTimeLabels = Object.keys(emailLabelToIDs);
const timezoneOffest = new Date().getTimezoneOffset();
let selectedTransactionalEmailsIndex = ("transactionalEmailNotifications" in ((_d = user === null || user === void 0 ? void 0 : user.account) === null || _d === void 0 ? void 0 : _d.zoomAccount)
    ? transactionalOptions.findIndex(o => o.id === user.account.zoomAccount.transactionalEmailNotifications)
    : 0) || 0;
let selectedEmailTimeIndex = closestTimeIndexForTimezone((_f = (_e = user === null || user === void 0 ? void 0 : user.account) === null || _e === void 0 ? void 0 : _e.zoomAccount) === null || _f === void 0 ? void 0 : _f.transactionalEmailUTCHour, timezoneOffest);
let selectedDayIndex = 1;
const onSubmit = (a) => __awaiter(void 0, void 0, void 0, function* () {
    var _g;
    const form = a.target;
    const data = new FormData(form);
    const selectorText = document.querySelector("button#filesToBackup > span").textContent;
    const option = uploadOptions.find(f => f.text == selectorText);
    const transactionalText = document.querySelector("button#transactionalEmailNotifications > span").textContent;
    const transactionalEmailNotifications = transactionalOptions.find(f => f.text == transactionalText).id;
    const emailTimeText = document.querySelector("#email-times button[aria-selected=true]").textContent;
    const transactionalEmailUTCHour = utcHourForTime(emailLabelToIDs[emailTimeText], timezoneOffest);
    const deleteAfterUpload = (_g = document.getElementById("delete-from-zoom")) === null || _g === void 0 ? void 0 : _g.checked;
    const request = {
        account: {
            contact: {
                firstName: data.get("firstname"),
                lastName: data.get("lastname"),
                email: data.get("email"),
            },
            confirmed: true,
        },
        zoom: {
            filesToBackup: option.id,
            transactionalEmailUTCHour,
            transactionalEmailNotifications,
            deleteAfterUpload,
        },
    };
    try {
        yield fetch(`${apiURL}/api/Zoom-ZoomDashboardUpdateAccount`, {
            credentials: "include",
            method: "post",
            body: JSON.stringify(request),
        });
        document.location.reload();
    }
    catch (error) {
        console.error(error);
    }
});
</script>

<div id="config">
  <Form on:submit={onSubmit}>
    <Grid>
      <Row>
        <Column>
          <FormGroup>
            <TextInput
              labelText="Email"
              placeholder="Enter user name..."
              type="email"
              name="email"
              value={contact.email}
            />
          </FormGroup>
        </Column>
      </Row>

      <Row>
        <Column aspectRatio="2x1">
          <TextInput labelText="First Name" placeholder="" name="firstname" value={contact.firstName} />
        </Column>
        <Column aspectRatio="2x1">
          <TextInput labelText="Last Name" placeholder="" name="lastname" value={contact.lastName} />
        </Column>
      </Row>

      <Row>
        <Column aspectRatio="2x1">
          <Dropdown
            titleText="Files to backup from Zoom"
            selectedIndex={selectedOption}
            items={uploadOptions}
            id="filesToBackup"
            name="filesToBackup"
          />
        </Column>
        {#if user?.account?.id.length < 4}
          <Column aspectRatio="2x1">
            <div class="labelish">
              <Checkbox
                id="delete-from-zoom"
                labelText="Delete files from Zoom after successful upload"
                checked={deleteAfterUpload}
                name="deleteAfterUpload"
              />
            </div>
          </Column>
        {/if}
      </Row>

      <div style="margin-top: 28px;">
        <Row>
          <Column aspectRatio="2x1">
            <Dropdown
              titleText="When would you prefer upload reports?"
              bind:selectedIndex={selectedTransactionalEmailsIndex}
              items={transactionalOptions}
              id="transactionalEmailNotifications"
              name="transactionalEmailNotifications"
            />
          </Column>
          <Column aspectRatio="2x1">
            <FormGroup legendText="When should we send emails? Using {userTimezone}">
              <span id="email-times">
                <ContentSwitcher bind:selectedIndex={selectedEmailTimeIndex}>
                  <Switch text={emailTimeLabels[0]} disabled={selectedTransactionalEmailsIndex < 2} />
                  <Switch text={emailTimeLabels[1]} disabled={selectedTransactionalEmailsIndex < 2} />
                  <Switch text={emailTimeLabels[2]} disabled={selectedTransactionalEmailsIndex < 2} />
                </ContentSwitcher>
              </span>
            </FormGroup>

            {#if selectedTransactionalEmailsIndex === 3}
              <FormGroup legendText="On what day?">
                <span id="email-times">
                  <ContentSwitcher bind:selectedIndex={selectedDayIndex}>
                    <Switch text="Mon" />
                    <Switch text="Wed" />
                    <Switch text="Fri" />
                  </ContentSwitcher>
                </span>
              </FormGroup>
            {/if}
          </Column>
        </Row>
      </div>

      <Row>
        <Column>
          <FormGroup>
            <center style="margin-top: 24px; text-align: right;">
              <Button type="submit">Submit</Button>
            </center>
          </FormGroup>
        </Column>
      </Row>
    </Grid>
  </Form>
</div>

<style>
  div#config {
    margin: auto;
    margin-top: 60px;
  }
  .labelish {
    margin-top: 35px;
  }
</style>
