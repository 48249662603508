<script lang="ts">import Config from "../setup/Config.svelte";
import Stripe from "../setup/Stripe.svelte";
import Oauth from "../setup/Oauth.svelte";
;
export let info;
</script>

<style>
  h2 {
    margin-bottom: 24px;
  }
  h3 {
    margin-bottom: 18px;
  }
</style>

<h2>Account Settings</h2>

<h3>Payment</h3>

<Stripe user={info} />

<h3>Your Settings</h3>

<Config user={info} />

<h3>Connections</h3>

<Oauth {info} />
