<script>
  import { Row } from "carbon-components-svelte"
  import Zoom from "../setup/Zoom.svelte"
</script>

<style>
  p {
    font-size: 1.4rem;
    color: #525252;
    max-width: 400px;
    margin-top: 2rem;
    margin-bottom: -4rem;
  }
  div {
    max-width: 700px;
    min-width: 320px;
    text-align: center;
    margin: auto;
    margin-top: 120px;
    background-color: #eee;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>

<Row>
  <div>
    <p>Log in via</p>
    <Zoom showAsLogin />
  </div>
</Row>
