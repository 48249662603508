<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Row, Column, Form, Grid } from "carbon-components-svelte";
;
const apiURL = process.env.KISS_SERVICES_FUNCS_URL;
let gdriveAuthURL = apiURL + "/api/Zoom-GoogleAuth";
let dropboxAuthURL = apiURL + "/api/Zoom-DropboxAuth";
export let info;
let googleConnected = info.account.zoomAccount && info.account.zoomAccount.deployments.find(d => d.type === "gdrive");
let dropboxConnected = info.account.zoomAccount && info.account.zoomAccount.deployments.find(d => d.type === "dropbox");
const disconnect = (remove) => __awaiter(void 0, void 0, void 0, function* () {
    console.log("disconnecting");
    try {
        yield fetch(`${apiURL}/api/Zoom-ZoomDashboardRemoveDeploymentTarget`, {
            credentials: "include",
            method: "post",
            body: JSON.stringify({ remove }),
        });
        document.location.reload();
    }
    catch (error) {
        console.error(error);
    }
});
const disconnectGoogleDrive = () => disconnect("gdrive");
const disconnectDropbox = () => disconnect("dropbox");
</script>

<style>
  h2 {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  p {
    font-size: 0.8rem;
    color: #525252;
    margin-bottom: 24px;
    max-width: 400px;
  }

  a {
    text-align: center;
    margin: auto;
    margin-top: 100px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  a:hover {
    margin-top: 101px;
  }
  a:active {
    margin-top: 102px;
  }
  img {
    margin-top: 8px;
    width: 200px;
    height: 44px;
  }
</style>

<Form>
  <Grid>
    <article>
      <Row>
        <Column aspectRatio="2x1">
          <h2>Dropbox</h2>
          {#if dropboxConnected}
            <p><a href="/" on:click={disconnectDropbox}>Stop transfering to Dropbox.</a></p>
          {:else}
            <p>Connect to Dropbox</p>

            <a href={dropboxAuthURL}><img
                src="/images/btn_dropbox_signin_light_normal_web.png"
                alt="Dropxox logo for clicking to start logging in" /></a>
          {/if}
        </Column>

        <Column aspectRatio="2x1">
          <h2>Google Drive</h2>
          {#if googleConnected}
            <p><a href="/" on:click={disconnectGoogleDrive}>Stop transfering to Google Drive.</a></p>
          {:else}
            <p>Connect to Google Drive</p>
            <a href={gdriveAuthURL}><img
                src="/images/btn_google_signin_light_normal_web@2x.png"
                alt="Google Drive logo for clicking to start logging in" /></a>
          {/if}
        </Column>
      </Row>
    </article>
  </Grid>
</Form>
